<template>
  <div class="gvia-main">
    <div class="wrapper">
        <div class="header">
            <h1>גבייה</h1>
        </div>
        <div class="content">
            <component 
                @move_to_payment="state='PaymentsClientDetails'" 
                @back_to_select_client="state='SelectClient'"
                :is="state" 
            />
        </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
export default {
    components:{
        SelectClient:defineAsyncComponent(()=>import('../../components/Agent_portal/Gvia/SelectClient.vue')),
        PaymentsClientDetails:defineAsyncComponent(()=>import('../../components/Agent_portal/Gvia/PaymentsClientDetails.vue'))
    },
    setup(){

        const state = ref('SelectClient')
        return{
            state
        }
    }
}
</script>

<style scoped>
    .gvia-main{
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 0 5px;
    }
    .gvia-main > .wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 5px 0;
    }
    .wrapper > .header{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--secondary);
        border-radius: 10px;
    }
    .wrapper > .content{
        padding: 10px 0 0 0;
        width: 100%;
        height: calc(100% - 50px);
    }
</style>